import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
// import * as Spotlight from '@spotlightjs/spotlight';
import { PUBLIC_ENVIRONMENT } from '$env/static/public';
import { beforeBreadcrumb } from './lib/log/sentry';

Sentry.init({
  dsn: 'https://aa592ed6b59c1cc72729f292aa2ef2e0@o4507644557328385.ingest.de.sentry.io/4507644567224400',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],

  environment: PUBLIC_ENVIRONMENT,
  beforeBreadcrumb: (breadcrumb) => beforeBreadcrumb(breadcrumb, 'client'),
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();

if (import.meta.env.DEV) {
  // Spotlight.init({
  //   injectImmediately: true,
  // });
}
